import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import CustomDatePicker from "../../../../components/input/CustomDatePicker";
import { useTranslation } from "react-i18next";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../contexts/AddEmissionContext";
import { Controller, useFormContext } from "react-hook-form";
import CustomInput from "../../../../components/input/CustomInput";
import { useEffect, useState } from "react";
import { EmissionData } from "../../../../models/dto/response/ResponseEmissionFactors";
import { CustomAutoComplete } from "../../../../components/input/CustomAutoComplete";
import { InputReactHookForm } from "../../../../components/input/InputReactHookForm";
import ArrayUtils from "../../../../utils/arrayUtils";
import { DROPDOWN_EMISSION_GROUP } from "../../../../constants/dropdown";
import { EmissionGroup } from "../../../../models/EmissionGroup";

const AddEmissionSectionOne = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const {
    stepOneForm,
    emissionFactorsMasterData,
    assetList,
    stepTwoForm,
    emissionMode,
  } = AddEmissionContext();

  const {
    setValue,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [mode, setMode] = useState<any | null>(null);

  const startDate = watch("startDate");

  const emissionGroup = stepOneForm.watch("emissionGroup");

  const activityGroup = stepTwoForm.watch("activityGroup");

  const activityType = stepTwoForm.watch("activityType");

  const activityAttribute = stepTwoForm.watch("activityAttribute");

  const unit = stepTwoForm.watch("unit");

  const [activityAttributeHl, setActivityAttributeHl] = useState("");

  const buildActivityGroupLabel = () => {
    if (emissionGroup) {
      const currentGroup: string = stepOneForm.getValues("emissionGroup") ?? "";

      const item: EmissionGroup | undefined = DROPDOWN_EMISSION_GROUP.find(
        (e) => e.emissionGroup === currentGroup
      );

      setActivityAttributeHl(t(item?.attributeName ?? ""));
    }
  };

  const changeValue = async (event: any) => {
    setMode(event);

    // กรณีล้างข้อมูลจะล้างตัว value dropdown ของตัวเองและตัวที่อยู่ภายใต้ด้วย
    if (event.target.value === null) {
      const targetName: string = event.target.name;

      switch (targetName) {
        case "activityType":
          setValue("activityType", null);

          setValue("activityAttribute", null);

          setValue("unit", null);

          break;
        case "activityAttribute":
          setValue("activityAttribute", null);

          setValue("unit", null);
          break;
        case "unit":
          setValue("unit", null);
          break;
        default:
          break;
      }
    }
  };
  // Utility function to set form value if menu has a single item
  const setSingleMenuValue = (
    menu: { value: string }[],
    form: any,
    field: string
  ) => {
    if (menu.length === 1) {
      form.setValue(field, menu[0].value, {
        shouldValidate: true,
      });
    }
  };

  // Utility function to clear form value if the event target value is null
  const clearFormValueOnNull = (
    event: React.ChangeEvent<HTMLInputElement>,
    form: any,
    field: string
  ) => {
    if (event.target.name === field && event.target.value === null) {
      form.setValue(field, null);
    }
  };

  const buildSelect = async () => {
    const activityGroupMenu = filterActivityGroup();

    const activityTypeMenu = filterActivityType();

    const activityAttributeMenu = filterActivityAttribute();

    const unitMenu = filterActivityUnit();

    autoSelect(
      activityGroupMenu,
      activityTypeMenu,
      activityAttributeMenu,
      unitMenu
    );
  };

  const autoSelect = (
    activityGroupMenu: any,
    activityTypeMenu: any,
    activityAttributeMenu: any,
    unitMenu: any
  ) => {
    if (mode) {
      const { value } = mode.target;

      if (value !== null) {
        setSingleMenuValue(activityGroupMenu, stepTwoForm, "activityGroup");

        setSingleMenuValue(
          activityAttributeMenu,
          stepTwoForm,
          "activityAttribute"
        );

        setSingleMenuValue(activityTypeMenu, stepTwoForm, "activityType");

        setSingleMenuValue(unitMenu, stepTwoForm, "unit");
      }

      // clearFormValueOnNull(mode, stepTwoForm, "activityAttribute");

      // clearFormValueOnNull(mode, stepTwoForm, "activityType");

      // clearFormValueOnNull(mode, stepTwoForm, "unit");
    } else {
      setSingleMenuValue(activityGroupMenu, stepTwoForm, "activityGroup");

      setSingleMenuValue(
        activityAttributeMenu,
        stepTwoForm,
        "activityAttribute"
      );

      setSingleMenuValue(activityTypeMenu, stepTwoForm, "activityType");

      setSingleMenuValue(unitMenu, stepTwoForm, "unit");
    }
  };

  const filterActivityGroup = () => {
    const activityType = stepTwoForm.getValues("activityType") ?? "";

    const activityAttribute = stepTwoForm.getValues("activityAttribute") ?? "";

    const unit = stepTwoForm.getValues("unit") ?? "";

    let filterResult: EmissionData[] = [...emissionFactorsMasterData];

    if (activityType !== "") {
      filterResult = filterResult.filter((e) => {
        return e.typeEn === activityType;
      });
    }
    if (activityAttribute !== "") {
      filterResult = filterResult.filter(
        (e) => e.attributeEn === activityAttribute
      );
    }

    if (unit !== "") {
      filterResult = filterResult.filter((e) => e.unit === unit);
    }

    const result = ArrayUtils.convertArrayToMenuData(
      "groupEn",
      "groupEn",
      "groupTh",
      ArrayUtils.filterArrayUniqueByKey("groupEn", filterResult)
    );

    return result;
  };

  const filterActivityType = () => {
    const activityGroup = stepTwoForm.getValues("activityGroup") ?? "";

    const activityAttribute = stepTwoForm.getValues("activityAttribute") ?? "";

    const unit = stepTwoForm.getValues("unit") ?? "";

    let filterResult: EmissionData[] = [...emissionFactorsMasterData];

    // if (activityGroup !== "") {
    //   filterResult = filterResult.filter((e) => e.groupEn === activityGroup);
    // }

    // if (activityAttribute !== "") {
    //   filterResult = filterResult.filter(
    //     (e) => e.attributeEn === activityAttribute
    //   );
    // }

    // if (unit !== "") {
    //   filterResult = filterResult.filter((e) => e.unit === unit);
    // }

    const result = ArrayUtils.convertArrayToMenuData(
      "typeEn",
      "typeEn",
      "typeTh",
      ArrayUtils.filterArrayUniqueByKey("typeEn", filterResult)
    );

    return result;
  };

  const filterActivityAttribute = () => {
    const activityGroup = stepTwoForm.getValues("activityGroup") ?? "";

    const activityType = stepTwoForm.getValues("activityType") ?? "";

    const unit = stepTwoForm.getValues("unit") ?? "";

    let filterResult: EmissionData[] = [...emissionFactorsMasterData];

    // if (activityGroup !== "") {
    //   filterResult = filterResult.filter((e) => e.groupEn === activityGroup);
    // }

    if (activityType !== "") {
      filterResult = filterResult.filter((e) => {
        return e.typeEn === activityType;
      });
    }

    // if (unit !== "") {
    //   filterResult = filterResult.filter((e) => e.unit === unit);
    // }

    const result = ArrayUtils.convertArrayToMenuData(
      "attributeEn",
      "attributeEn",
      "attributeTh",
      ArrayUtils.filterArrayUniqueByKey("attributeEn", filterResult)
    );

    return result;
  };

  const filterActivityUnit = () => {
    const activityGroup = stepTwoForm.getValues("activityGroup") ?? "";

    const activityType = stepTwoForm.getValues("activityType") ?? "";

    const activityAttribute = stepTwoForm.getValues("activityAttribute") ?? "";

    let filterResult: EmissionData[] = [...emissionFactorsMasterData];

    // if (activityGroup !== "") {
    //   filterResult = filterResult.filter((e) => e.groupEn === activityGroup);
    // }

    if (activityType !== "") {
      filterResult = filterResult.filter((e) => {
        return e.typeEn === activityType;
      });
    }

    if (activityAttribute !== "") {
      filterResult = filterResult.filter(
        (e) => e.attributeEn === activityAttribute
      );
    }

    const result = ArrayUtils.convertArrayToMenuData(
      "unit",
      "unit",
      "unit",
      ArrayUtils.filterArrayUniqueByKey("unit", filterResult)
    );

    return result;

    // if (!ArrayUtils.arraysEqual(result, unitMenu)) {
    //   setUnitMenu(result);
    // }
  };

  useEffect(() => {
    // first rednder render

    if (mode === null) {
      buildActivityGroupLabel();
    }

    buildSelect();

    const emissionFactors: EmissionData | undefined =
      emissionFactorsMasterData.find(
        (e) =>
          e.attributeEn === getValues("activityAttribute") &&
          e.typeEn === getValues("activityType") &&
          e.unit === getValues("unit")
      );

    if (emissionFactors) {
      try {
        setValue(
          "activityEmissionFactorPublisher",
          emissionFactors.publisherRef!.publishReference
        );
      } catch (error) {
        setValue("activityEmissionFactorPublisher", "-");
      }
    } else {
      setValue("activityEmissionFactorPublisher", "");
    }
  }, [
    emissionFactorsMasterData,
    activityGroup,
    activityType,
    activityAttribute,
    unit,
  ]);

  return (
    <>
      {emissionMode === EmissionMode.AddEmission ? (
        <>
          {/* AddEmission Mode */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Controller
                name="startDate"
                control={control}
                defaultValue={dayjs()}
                rules={{
                  required: true,
                  onChange: () => trigger("endDate"),
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomDatePicker
                    value={value}
                    label={t("Start-Date")}
                    info={t("Start-Date")}
                    required
                    handleOnChange={onChange}
                    defaultDate={value}
                    inputRef={ref}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Controller
                name="endDate"
                control={control}
                defaultValue={dayjs()}
                rules={{
                  validate: (value) => {
                    const start = dayjs(startDate);

                    const end = dayjs(value);

                    return (
                      value == null ||
                      start.isSame(end, "month") ||
                      start.isBefore(end, "month") ||
                      t("end-date-must-be-after-start-date")
                    );
                  },
                }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <CustomDatePicker
                    value={value}
                    label={t("End-Date")}
                    info={t("End-Date")}
                    handleOnChange={onChange}
                    defaultDate={null}
                    inputRef={ref}
                    error={error}
                    initDate={getValues("startDate") ?? null}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        <>
          {/* AddActivityTemplates */}
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Controller
                name="year"
                control={control}
                defaultValue={dayjs()}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomDatePicker
                    value={value}
                    label={t("Year")}
                    info={t("Year")}
                    required
                    handleOnChange={onChange}
                    defaultDate={value}
                    inputRef={ref}
                    format="YYYY"
                    views={["year"]}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} maxHeight={"69.13px"}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"end"}
              justifyContent={"start"}
              height={"100%"}
            >
              <Controller
                name="monthly"
                control={control}
                defaultValue={getValues("monthly") === true ? true : false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} name="monthly" />}
                    value={value}
                    onChange={onChange}
                    label={
                      <Typography variant="text1">{t("Monthly")}</Typography>
                    }
                  />
                )}
              />
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs={12} md={6}>
        <CustomInput
          label={t("Asset-Name")}
          info={t("Asset-Name-Description")}
          value={
            assetList.find((i) => i._id === stepOneForm.getValues("assetName"))
              ?.name ?? ""
          }
          disabled
          required
        />
      </Grid>

      <Hidden mdDown>
        <Grid item xs={0} md={6}></Grid>
      </Hidden>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required
          name="activityType"
          defaultValue={getValues("activityType") ?? null}
          rules={{
            required: t("form-reqired-error", {
              param: t("Activity-Type"),
            }),
            onChange: (event) => {
              setValue("activityAttribute", null);

              setValue("unit", null);
              changeValue(event);
            },
          }}
          control={stepTwoForm.control}
          label={t("Activity-Type")}
          info={t("Activity-Type")}
          placeholder={t("Select-Param", {
            param: t("Activity-Type"),
          })}
          listMenuItem={filterActivityType()}
        />
      </Grid>

      <Hidden mdDown>
        <Grid item xs={0} md={6}></Grid>
      </Hidden>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required
          name="activityAttribute"
          defaultValue={getValues("activityAttribute") ?? null}
          rules={{
            required: t("form-reqired-error", {
              param: t("Activity-Attribute"),
            }),
            onChange: (event) => {
              setValue("unit", null);
              changeValue(event);
            },
          }}
          control={stepTwoForm.control}
          label={activityAttributeHl}
          info={activityAttributeHl}
          placeholder={t("Select-Param", {
            param: activityAttributeHl,
          })}
          listMenuItem={filterActivityAttribute()}
          disabled={getValues("activityType") === null ? true : false}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="activityEmissionFactorPublisher"
          control={stepTwoForm.control}
          defaultValue={getValues("activityEmissionFactorPublisher") ?? ""}
          label={t("Activity-Emission-Factor-Publisher")}
          info={t("Activity-Emission-Factor-Publisher")}
          placeholder={""}
          disabled
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <Controller
            name="vendor"
            control={stepTwoForm.control}
            defaultValue={stepTwoForm.getValues("vendor") ?? ""}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                label={t("Vendor")}
                info={t("Vendor")}
                placeholder={t("Select-Param", {
                  param: t("Vendor"),
                })}
                value={value}
                handleOnChange={onChange}
              />
            )}
          />
        </FormControl>
      </Grid>

      <Hidden mdDown>
        <Grid item xs={0} md={6}></Grid>
      </Hidden>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required
          name="unit"
          defaultValue={getValues("unit") ?? null}
          rules={{
            required: t("form-reqired-error", {
              param: t("Unit"),
            }),
            onChange: changeValue,
          }}
          control={stepTwoForm.control}
          label={t("Unit")}
          info={t("Unit")}
          placeholder={t("Select-Param", {
            param: t("Unit"),
          })}
          listMenuItem={filterActivityUnit()}
          disabled={getValues("activityAttribute") === null ? true : false}
        />
      </Grid>
    </>
  );
};

export default AddEmissionSectionOne;
