import { Box } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import AddEmissionForm from "../popup-add-emission/PopupAddEmission";
import { CustomGridToolbarQuickFilter } from "../../../components/datagrid/CustomGridToolbarQuickFilter";
import CustomDatePicker from "../../../components/input/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { AddEmissionContext } from "../../../contexts/AddEmissionContext";
import ScopeSummary from "../ScopeSummary";

export const QuickSearchToolbar = () => {
  // const handleExport = () => {
  //   apiRef.current.exportDataAsCsv({ fileName: "emission_report" });
  // };

  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { year, setYear } = AddEmissionContext();

  const handleOnChange = (event: any) => {
    setYear(event);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"40px"}
      marginBottom={"40px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"self-end"}
        gap={4}
      >
        <Box
          display={"flex"}
          columnGap={"12px"}
          flex={1}
          alignItems={"self-end"}
        >
          <CustomGridToolbarQuickFilter />

          <GridToolbarContainer>
            <CustomDatePicker
              value={year}
              label={t("Year")}
              info={t("Year")}
              required
              handleOnChange={handleOnChange}
              defaultDate={year}
              views={["year"]}
              showInfo={false}
              format="YYYY"
            />
          </GridToolbarContainer>
        </Box>

        <GridToolbarContainer
          sx={{
            padding: 0,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <AddEmissionForm />
        </GridToolbarContainer>
      </Box>
      <ScopeSummary />
    </Box>
  );
};
