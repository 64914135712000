import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Avatar, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import LoginLayout from "../../Layout/LoginLayout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/UserContext";
import { OrganizationsRef } from "../../models/User";
import { stringAvatar } from "../../utils/avatar";

const SelectOrganization: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["common"]);

  const { user, organization, selectOrganization, logout } = useAuth();

  const redirectToApp = async (item: OrganizationsRef) => {
    selectOrganization(item);

    navigate("/");
  };

  useEffect(() => {
    if (user?.organizationsRef.length === 1) {
      redirectToApp(user?.organizationsRef[0]);
    }
  }, [user, organization]);

  const [search, setSearch] = useState<string>("");

  return (
    <LoginLayout>
      <Box
        maxWidth={"27rem"}
        padding={"16px"}
        display={"flex"}
        flexDirection={"column"}
        // rowGap={4}
        width={"100%"}
      >
        <img
          src="/img/login/CarbonCalLogoLong.svg"
          alt="logo"
          style={{
            height: "28px",
            width: "233px",
            marginBottom: "40px",
          }}
        />

        <Typography variant="h3">{t("Please-Select-Organization")}</Typography>

        <TextField
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          placeholder={`${t("search")}...`}
          style={{
            marginTop: "20px",
            // margin: "20px 0px",
          }}
        />

        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          flex={1}
          minHeight={"248px"}
          maxHeight={"248px"}
          overflow={"auto"}
          marginY={"20px"}
        >
          {user?.organizationsRef!.map((item, index) => {
            return item.name.toLowerCase().includes(search) ? (
              <Button
                key={item.id}
                variant="text"
                fullWidth
                sx={{
                  textAlign: "left",
                  justifyContent: "start",
                  paddingX: 0,
                  paddingY: 0,
                  color: "var(--dark)",
                  ":hover": {
                    backgroundColor: "var(--green)",
                    color: "var(--white)",
                  },
                }}
                onClick={() => redirectToApp(item)}
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={
                      process.env.REACT_APP_IS_OPEN_SCOPE_3 === "false"
                        ? "/img/camp.png"
                        : undefined
                    }
                    {...stringAvatar(item.name ?? "Organization")}
                    alt={item.name}
                    sx={{
                      width: 58,
                      height: 58,
                      marginRight: 2,
                      backgroundColor:
                        process.env.REACT_APP_IS_OPEN_SCOPE_3 === "false"
                          ? "var(--gray3)"
                          : "var(--green)",
                    }}
                  ></Avatar>
                  <Box>
                    <Typography variant="h3">{item.name}</Typography>
                  </Box>
                </Box>
              </Button>
            ) : null;
          })}
        </Box>

        <Button
          onClick={logout}
          variant="outlined"
          fullWidth
          sx={{
            border: "1px solid var(--dark)",
            color: "var(--dark)",
            marginTop: "40px",
            marginX: "0px",
            "&:hover": {
              border: "1px solid var(--dark)",
              color: "var(--dark)",
              opacity: 0.75,
            },
          }}
        >
          {t("Logout")}
        </Button>
      </Box>
    </LoginLayout>
  );
};

export default SelectOrganization;
