import { Box } from "@mui/material";
import Layout from "../../../Layout";
import { ReportDataTable } from "../ReportDataTable";
import { ReportProvider } from "../../../contexts/ReportContext";
import { ReportMenu } from "../ReportMenu";

export const ReportSummary = () => {
  return (
    <>
      <Layout>
        <ReportMenu />
        <Box display={"flex"} flexDirection={"column"} flex={1}>
          <Box
            p={"40px 40px"}
            display={"flex"}
            flexDirection="column"
            bgcolor={"var(--white)"}
            gap={"40px"}
            alignItems={"start"}
            flex={1}
          >
            <ReportProvider>
              <ReportDataTable reportDataTableType="summary" />
            </ReportProvider>
          </Box>
        </Box>
      </Layout>
    </>
  );
};
