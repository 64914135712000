import { InputAdornment } from "@mui/material";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useState } from "react";

export const CustomGridToolbarQuickFilter = () => {
  const [inputOpen, setInputOpen] = useState<boolean>(false);

  const onMouseEnter = () => {
    setInputOpen(true);
  };

  const onBlur = (event: any) => {
    const input: any = document.getElementById("GridToolbarQuickFilter");

    if (input.value === "") {
      setInputOpen(false);
    }
  };

  return (
    <GridToolbarContainer
      sx={{
        padding: 0,
        width: { xs: "100%", sm: "auto" },
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          margin: 0,
          padding: 0,
          height: "40px",
          // transition: inputOpen ? ".0s" : ".5s",
          transition: ".5s",
          width: inputOpen ? "100%" : "50px",
          "& .MuiInput-root": {
            border: "1px solid #AFAFAF",
            borderRadius: "8px",
          },
          "& .MuiInput-root:hover": {
            border: "1px solid var(--gray1)",
            borderRadius: "8px",
          },
          "& .Mui-focused": {
            border: "1px solid var(--green) !important",
          },
        }}
        inputProps={{
          style: {
            padding: "0px",
          },
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <img src="/img/search-normal.svg" alt="search" />
            </InputAdornment>
          ),
          sx: {
            padding: "7px 8px 8px 8px",
            margin: 0,
          },
        }}
        onMouseEnter={onMouseEnter}
        // onMouseLeave={onBlur}
        onBlur={onBlur}
        id="GridToolbarQuickFilter"
      />
    </GridToolbarContainer>
  );
};
