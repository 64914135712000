import {
  Card,
  CardContent,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EmissionByBusinessGroup, {
  LowToHigh,
  bySite,
  highToLow,
} from "./EmissionByBusinessGroup";
import { EmissionData } from "../../models/Emission";
import Group from "../../models/Group";

export const scopes: Group[] =
  process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
    ? [
        {
          id: "Scope-1",
          title: "Scope-1",
          colors: ["var(--blue-25)", "var(--blue)"],
        },
        {
          id: "Scope-2",
          title: "Scope-2",
          colors: ["var(--red-25)", "var(--red)"],
        },
        {
          id: "Scope-3",
          title: "Scope-3",
          colors: ["var(--yellow-25)", "var(--yellow)"],
        },
        {
          id: "Outside-of-Scope",
          title: "Outside-of-Scope",
          colors: ["var(--Outside-of-Scope-25)", "var(--Outside-of-Scope)"],
        },
      ]
    : [
        {
          id: "Scope-1",
          title: "Scope-1",
          colors: ["var(--blue-25)", "var(--blue)"],
        },
        {
          id: "Scope-2",
          title: "Scope-2",
          colors: ["var(--red-25)", "var(--red)"],
        },
        {
          id: "Outside-of-Scope",
          title: "Outside-of-Scope",
          colors: ["var(--Outside-of-Scope-25)", "var(--Outside-of-Scope)"],
        },
      ];

type EmissionByBusinessCardType = "suborganization" | "site";

const EmissionByBusinessCard = () => {
  const [sort, setSort] = useState<number>(highToLow);
  const [by, setBy] = useState(bySite);

  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const onSelectSortChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setSort(parseInt(value));
    } else {
      setSort(value);
    }
  };

  const onSelectByChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setBy(parseInt(value));
    } else {
      setBy(value);
    }
  };

  const getGroup = (emission: EmissionData) => {
    return emission.scope;
  };

  const [byType, setByType] =
    useState<EmissionByBusinessCardType>("suborganization");

  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", p: "8px" }}>
      <CardContent>
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          justifyContent={"end"}
          mb={"30px"}
          gap={"12px"}
          alignItems={"center"}
        >
          <Typography
            variant="body1"
            flexGrow={1}
            width={{
              xs: "100%",
              sm: "unset",
            }}
          >
            {/* {byType === "suborganization"
              ? t("Emissions-by-Business-Structure")
              : t("Emissions-by-Site")} */}
            {t("Emissions-by-Business-Structure")}
            <InfoPopper
              text={t("Emissions-by-Business-Structure-Desc")}
              // text={
              //   byType === "suborganization"
              //     ? t("Emissions-by-Business-Structure-Desc")
              //     : t("Emissions-by-Site-Desc")
              // }
            />
          </Typography>
          <img src="/img/setting-5.svg" alt="setting" />
          <FormControl variant="standard">
            <Select defaultValue={highToLow} onChange={onSelectSortChange}>
              <MenuItem value={highToLow}>{t("High-to-Low")}</MenuItem>
              <MenuItem value={LowToHigh}>{t("Low-to-High")}</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="standard"
            sx={{
              width: "160px",
            }}
          >
            <Select
              value={byType}
              defaultValue={"suborganization"}
              onChange={(e) =>
                setByType(e.target.value as EmissionByBusinessCardType)
              }
            >
              <MenuItem value={"suborganization"}>{t("By-Sub-Org")}</MenuItem>
              <MenuItem value={"site"}>{t("By-Site")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <EmissionByBusinessGroup
          groups={scopes}
          getGroup={getGroup}
          by={byType === "suborganization" ? 2 : 1}
          sort={sort}
          top={5}
        />
      </CardContent>
    </Card>
  );
};

export default EmissionByBusinessCard;
