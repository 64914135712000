import {
  Autocomplete,
  Checkbox,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import {
  Control,
  RegisterOptions,
  FieldValues,
  Controller,
} from "react-hook-form";
import { MenuData } from "./CustomSelect";
import LabelInput from "./LabelInput";
import { useEffect, useState } from "react";
import { CustomFormHelperText } from "./CustomFormHelperText";

interface Props {
  name: string;
  control: Control<any, any>;
  rules:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  listMenuItem: MenuData[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
}

export const MultipleAutoComplete = (props: Props) => {
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    const temp = props.listMenuItem.map((item) => item.nameEN);

    setOptions(temp);
  }, []);

  return (
    <>
      <FormControl fullWidth>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          defaultValue={props.defaultValue ?? []}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <>
                <Typography variant="h1" color={"dark"}></Typography>
                <LabelInput
                  label={props.label}
                  info={props.info}
                  required={props.required}
                />
                <Autocomplete
                  size="small"
                  multiple
                  limitTags={2}
                  options={options}
                  value={value}
                  defaultValue={props.defaultValue ?? []}
                  disableCloseOnSelect
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option}
                    </li>
                  )}
                  sx={{
                    width: "100%",
                    backgroundColor: "var(--white)",
                    "& .MuiAutocomplete-tag": {
                      maxWidth: "40%",
                      fontSize: "12px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        zIndex: 0,
                        "& .MuiOutlinedInput-root": {
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                        "& .MuiInputBase-sizeSmall": {
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        },
                      }}
                      error={error ? true : false}
                    />
                  )}
                  onChange={(event, value, reson) => {
                    onChange(value);
                  }}
                />
                <CustomFormHelperText error={error} />
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};
