import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { MenuData } from "./CustomSelect";
import LabelInput from "./LabelInput";
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { CustomFormHelperText } from "./CustomFormHelperText";

interface Props {
  name: string;
  control: Control<any, any>;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
  label: string;
  info: string;
  placeholder: string;
  listMenuItem: MenuData[];
  required?: boolean;
  disabled?: boolean;
  defaultValue?: any;
}

export const SelectCheckmarks = (props: Props) => {
  const [inputValue, setInputValue] = useState<string[]>([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setInputValue(typeof value === "string" ? value.split(",") : value);
  };

  const [list, setList] = useState<string[]>([]);

  useEffect(() => {
    const result: string[] = props.listMenuItem.map((e) => e.nameEN);

    setList(result);

    setInputValue([...result]);
  }, []);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.defaultValue}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        return (
          <>
            <FormControl fullWidth>
              <Box
                sx={{
                  marginBottom: "8px",
                }}
              >
                <LabelInput
                  label={props.label}
                  info={props.info}
                  required={props.required}
                />
              </Box>
              <Select
                id={props.name}
                key={props.name}
                multiple
                value={inputValue}
                onChange={(event) => {
                  onChange(event);
                  handleChange(event);
                }}
                renderValue={(selected) => {
                  return selected.join(", ");
                }}
                error={error ? true : false}
              >
                {list.map((i) => (
                  <MenuItem
                    key={i}
                    value={i}
                    sx={{
                      "&.Mui-selected:not(.Mui-disabled)": {
                        backgroundColor: "transparent",
                        color: "var(--white)",
                        ".MuiListItemText-primary": {
                          color: "var(--dark)",
                        },
                        "&.MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "var(--green3)",
                        },
                      },
                    }}
                  >
                    <Checkbox checked={inputValue.includes(i) ? true : false} />
                    <ListItemText primary={i} color="var(--dark)" />
                  </MenuItem>
                ))}
              </Select>

              <CustomFormHelperText error={error} />
            </FormControl>
          </>
        );
      }}
    />
  );
};
