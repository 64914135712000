import {
  Card,
  CardContent,
  Box,
  Avatar,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  ChartsItemContentProps,
  PieChart,
  PieValueType,
  useDrawingArea,
} from "@mui/x-charts";
import PieDetail from "../chart/PieDetail";
import { useTranslation } from "react-i18next";
import InfoPopper from "../popper/InfoPopper";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { useState } from "react";
import PieTooltip from "../chart/PieTooltip";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";

interface Props {
  title: string;
  icon: string;
  info: string;
  data: MakeOptional<PieValueType, "id">[];
}

function PieCenterLabel({ value }: { value: number }) {
  const { width, height, left, top } = useDrawingArea();
  const x = left + width / 2;
  return (
    <text
      textAnchor={"middle"}
      dominantBaseline={"central"}
      x={x}
      y={top + height / 2}
      fontFamily="Inter"
    >
      <tspan x={x} dy={"-10px"} fontSize={"16px"}>
        {NumberFormatterUtils.numberFormat(value)}
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"12px"}>
        tC02e
      </tspan>
    </text>
  );
}

const OverviewPieCard = (props: Props) => {
  const [sort, setSort] = useState<number>(1);

  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const tooltip = (props: ChartsItemContentProps) => {
    return PieTooltip(props, t);
  };

  const onSelectSortChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setSort(parseInt(value));
    } else {
      setSort(value);
    }
  };

  let totalValue = 0;
  props.data.forEach((item) => {
    const seriesItem = item as PieValueType;
    totalValue += seriesItem.value;
  });

  let filteredData;
  if (sort === 0) {
    filteredData = props.data.sort((a, b) => a.value - b.value);
  } else {
    filteredData = props.data.sort((a, b) => b.value - a.value);
  }

  const details = filteredData.map((item, index) => {
    return (
      <PieDetail
        key={`pieDetail${index}`}
        title={item.label ? t(item.label) : ""}
        percent={(item.value / totalValue) * 100}
        color={item.color ?? "Outside-of-Scope"}
      />
    );
  });

  if (filteredData.length === 0) {
    filteredData = [{ id: 0, value: 0.001, label: "", color: "var(--gray6)" }];
  }

  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", height: "100%" }}>
      <CardContent sx={{ height: "100%", p: "24px 12px 24px 12px" }}>
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <Box display={"flex"} gap={"4px"} alignItems={"center"}>
            <Avatar src={props.icon} sx={{ width: "33px", height: "33px" }} />
            <Typography variant="body1" flexGrow={1}>
              {props.title}
              <InfoPopper text={props.info} />
            </Typography>
            <img src="/img/setting-5.svg" alt="setting" />
            <FormControl variant="standard">
              <Select defaultValue={1} onChange={onSelectSortChange}>
                <MenuItem value={1}>{t("High-to-Low")}</MenuItem>
                <MenuItem value={0}>{t("Low-to-High")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box flexShrink={0} display={"flex"}>
            <PieChart
              series={[
                {
                  data: filteredData,
                  innerRadius: "50%",
                  outerRadius: "100%",
                },
              ]}
              margin={{ top: 25, bottom: 25, left: 25, right: 25 }}
              width={270}
              height={270}
              slotProps={{
                legend: {
                  hidden: true,
                },
              }}
              tooltip={{
                // trigger: "item",
                trigger: filteredData[0].value === 0.001 ? "none" : "item",

                itemContent: tooltip,
              }}
              slots={{
                itemContent: tooltip,
              }}
            >
              <PieCenterLabel value={totalValue} />
            </PieChart>
          </Box>
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            pr={"1rem"}
            gap={"12px"}
            sx={{
              overflowY: "scroll",
            }}
          >
            {details}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OverviewPieCard;
