import { Box, Button, Hidden } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/UserContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { useForm } from "react-hook-form";
import { AutoLocation } from "./AutoLocation";
import SubOrganizationsService from "../../service/subOrganizationsService";
import alertService from "../../components/alert/alertService";
import { Site, SubOrganizations } from "../../models/SubOrganization";

type SiteProfileMode = "add" | "edit";

interface Props {
  mode: SiteProfileMode;
}

interface FormValues {
  name: string;
  city: string;
  postalCode: string;
  country: string;
  address: string;
  district: string;
  subDistrict: string;
  subOrganization: string;
}

export const SiteProfile = (props: Props) => {
  const { t } = useTranslation(["common", "organization", "input"], {
    nsMode: "fallback",
  });

  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const { isAdmin } = useAuth();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = async (values: FormValues) => {
    const body: any = {
      _id: site ? site?.refId : "",
      suborganizationRef: subOrganization?.refId,
      name: values.name,
      location: {
        city: values.city,
        postalCode: values.postalCode,
        country: values.country,
        address: values.address,
        district: values.district,
        subdistrict: values.subDistrict,
      },
    };

    if (props.mode === "add") {
      delete body._id;
    }

    if (values.name.trim() === site?.name.trim()) {
      delete body.name;
    }

    if (body.location.address.trim() === "") {
      delete body.location.address;
    }

    const result = await SubOrganizationsService.editSite(body);

    if (result) {
      const temp = [...context.subOrganizations!];

      const index = temp.findIndex((e) => e.refId === subOrganization?.refId);

      if (index > -1) {
        if (props.mode === "add") {
          temp[index].sites.push(result);
        } else {
          const siteIndex = temp[index].sites.findIndex(
            (s) => s.refId === result.refId
          );

          temp[index].sites[siteIndex] = result;
        }
        context.setSubOrganizations(temp);
        alertService.mixin(t("Successfully"));
        context.setOpenModal(false);
      }
    }
  };

  const [subOrganization, setSubOrganization] = useState<SubOrganizations>();

  const [site, setSite] = useState<Site>();

  useEffect(() => {
    const item = context.subOrganizations?.find(
      (item) => item.id === context.selectedSubOrganization
    );

    if (!item) return;

    clearErrors();

    setSubOrganization(item);

    setValue("subOrganization", item.name);

    if (props.mode === "edit") {
      const site = item?.sites.find(
        (s) => s.id.toString() === context.selectedSite
      );

      if (site) {
        setSite(site);
        setValue("name", site.name);

        setValue("country", site.location.country ?? "ไทย");
        setValue("city", site.location.city ?? "");
        setValue("district", site.location.district ?? "");
        setValue("subDistrict", site.location.subdistrict ?? "");
        setValue("postalCode", site.location.postalCode ?? "");
        setValue("address", site.location.address ?? "");
      }
    }
  }, [context.selectedSubOrganization, context.selectedSite]);

  // useEffect(() => {
  //   console.log("11");
  // }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Box
            flex={1}
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowGap={"20px"}
              columnSpacing={"40px"}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Organizations")}
                  info={t("Organizations")}
                  name="organization"
                  placeholder={t("Organizations")}
                  control={control}
                  defaultValue={context.organization?.name ?? ""}
                  required
                  disabled
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Sub-Organizations")}
                  info={t("Sub-Organizations")}
                  name="subOrganization"
                  placeholder={t("Sub-Organizations")}
                  control={control}
                  defaultValue={""}
                  required
                  disabled
                />
              </Grid2>

              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  label={t("Site-Name")}
                  info={t("Site-Name")}
                  name="name"
                  placeholder={t("Site-Name")}
                  control={control}
                  defaultValue={""}
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Site-Name"),
                    }),
                    validate: (text: string) => {
                      const index = subOrganization?.sites.findIndex(
                        (s) =>
                          s.name.trim().toLowerCase() ===
                          text.trim().toLowerCase()
                      );

                      return index !== undefined &&
                        index >= 0 &&
                        site?.name !== text
                        ? t("SITE_ALREADY_EXISTS")
                        : true;
                    },
                  }}
                />
              </Grid2>

              <Hidden mdDown>
                <Grid2 xs={12} md={6}></Grid2>
              </Hidden>

              <AutoLocation
                control={control}
                setValue={setValue}
                watch={watch}
              />
              <Grid2 xs={12}>
                {isAdmin() && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!isValid}
                  >
                    {props.mode === "edit" ? t("Save") : t("Add")}
                  </Button>
                )}
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </form>
    </>
  );
};
