import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { Site, SubOrganizations } from "../models/SubOrganization";

const SubOrganizationsService = {
  editSubOrganizations: async (
    formData: any
  ): Promise<SubOrganizations[] | null> => {
    try {
      const response = await apiClient.post("/v1/suborganizations", formData);

      if (response.status === 200) {
        const result: SubOrganizations[] =
          response.data.results.suborganizations;
        return result;
      }
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
  editSite: async (formData: any): Promise<Site | null> => {
    try {
      const response = await apiClient.post("/v1/sites", formData);

      if (response.status === 200) {
        const result: any = response.data.results.site;

        return {
          refId: result._id,
          id: result.id,
          name: result.name,
          location: result.location,
        };
      }
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
};

const handleError = async (error: any) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;
    switch (errorMessage) {
      case "VALIDATION_ERROR":
        alertService.error(`${t("validation-failed")}`);
        break;
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "ACTIVITY_UPSERT_PROCESS_FAILED":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "SITE_ALREADY_EXISTS":
        alertService.error(t("SITE_ALREADY_EXISTS"));
        break;
      case "SUBORGANIZATION_ALREADY_EXISTS":
        alertService.error(t("SUBORGANIZATION_ALREADY_EXISTS"));
        break;
      case "FORBIDDEN_ACTION_ERROR":
        alertService.info(
          t("FORBIDDEN_ACTION_ERROR"),
          t("Please-Upgrade-Package")
        );
        break;
      case "PLAN_EXPIRED":
        alertService.info(t("PLAN_EXPIRED"), t("Please-Upgrade-Package"));
        break;
      default:
        alertService.error(t("Cannot-Access-Organization-Error"));
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};

export default SubOrganizationsService;
