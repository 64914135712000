import { useEffect } from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AddEmissionContext,
  EmissionMode,
  FormValuesStepOne,
  FormValuesStepTwo,
} from "../../../contexts/AddEmissionContext";

import AddEmissionStepTwo from "./step2/AddEmissionStepTwo";
import { FormProvider } from "react-hook-form";
import alertService from "../../../components/alert/alertService";
import ActivitiesService from "../../../service/activitiesService";
import AddEmissionStepOne from "./step1/AddEmissionStepOne";
import { GetActivities } from "../../../models/Activities";
import CustomModal from "../../../components/modal/CustomModal";
import { OrganizationBreadcrumbs } from "../OrganizationBreadcrumbs";
import dayjs, { Dayjs } from "dayjs";

const AddEmissionForm = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const {
    step,
    setStep,
    open,
    handleOpen,
    handleClose,
    stepOneForm,
    stepTwoForm,
    prepareFormDataToCreateOrDraftActivities,
    setUpActivitiesListData,
    setEmissionMode,
    subOrganizationList,
    year,
    setYear,
  } = AddEmissionContext();

  const closeModal = () => {
    stepOneForm.reset();
    stepTwoForm.reset();
    handleClose();
  };

  const stepOneOnSubmit = (data: FormValuesStepOne) => {
    setStep(step + 1);
  };

  const stepTwoOnSubmit = async (data: FormValuesStepTwo, event: any) => {
    // if (event.nativeEvent.submitter.name === "add") {
    //   const attachment = stepTwoForm.getValues("attachment") ?? "";

    //   if (attachment === "") {
    //     alertService.info(t("please-upload-evidence-file"));

    //     return;
    //   }
    // }

    const createActivitiesResult: GetActivities | null =
      await ActivitiesService.createActivities(
        prepareFormDataToCreateOrDraftActivities()
      );

    if (createActivitiesResult !== null) {
      setUpActivitiesListData(createActivitiesResult);

      const updateYear: Dayjs = dayjs(
        createActivitiesResult.activities[0].startDate
      );

      if (year.year() !== updateYear.year()) {
        setYear(updateYear);
      }

      closeModal();

      alertService.mixin(t("emission-created-successfully"));
    }
  };

  useEffect(() => {
    setEmissionMode(EmissionMode.AddEmission);
  }, []);

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/add-circle.svg" alt="add-circle" />}
        onClick={handleOpen}
      >
        {t("Add-Emission")}
      </Button>

      <CustomModal
        header={
          <>
            <Typography variant="h6">
              {step === 2 ? (
                <IconButton
                  sx={{
                    marginLeft: "-8px",
                  }}
                  onClick={() => {
                    stepTwoForm.reset();
                    setStep(step - 1);
                  }}
                >
                  <img src="/img/arrow-left.svg" alt="" />
                </IconButton>
              ) : (
                <span></span>
              )}
              {t("Add-Emission")}({step}/2)
            </Typography>
            {step === 2 ? (
              <OrganizationBreadcrumbs
                organization={stepOneForm.getValues("organization") ?? ""}
                location={stepOneForm.getValues("location") ?? ""}
                subOrganizations={
                  subOrganizationList.find(
                    (i) => i.id === stepOneForm.getValues("subOrganizations")
                  )?.name ?? ""
                }
                site={subOrganizationList
                  .find(
                    (i) => i.id === stepOneForm.getValues("subOrganizations")
                  )
                  ?.sites.find(
                    (site) => site.refId === stepOneForm.getValues("site")
                  )}
              />
            ) : null}
          </>
        }
        body={
          <>
            {step === 1 ? (
              <FormProvider {...stepOneForm}>
                <form onSubmit={stepOneForm.handleSubmit(stepOneOnSubmit)}>
                  <Box>
                    <AddEmissionStepOne />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                    >
                      {t("Next")}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            ) : (
              <FormProvider {...stepTwoForm}>
                <form onSubmit={stepTwoForm.handleSubmit(stepTwoOnSubmit)}>
                  <AddEmissionStepTwo />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingTop: "44px",
                    }}
                    rowGap={2.5}
                  >
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      type="submit"
                      name="add"
                    >
                      {t("Add")}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            )}
          </>
        }
        open={open}
        onClose={closeModal}
      />
    </>
  );
};

export default AddEmissionForm;
