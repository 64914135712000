import { useContext } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DashbroadContext } from "../../contexts/DashbroadContext";
import { useTranslation } from "react-i18next";
import OverviewCard from "../../components/dashboard/OverviewCard";
import OverviewPieCard from "../../components/dashboard/OverviewPieCard";
import EmissionCard, { byChart } from "../../components/dashboard/EmissionCard";
import { EmissionData } from "../../models/Emission";
import EmissionByCard from "../../components/dashboard/EmissionByCard";
import EmissionByBusinessCard, {
  scopes,
} from "../../components/dashboard/EmissionByBusinessCard";
import "./index.css";
import NumberFormatterUtils from "../../utils/numberFormatterUtils";
import { REPORT_GROUP } from "../../constants/reportGroup";
import { TFunction } from "i18next";

const getTypeSeries = (
  t: TFunction<[string, string], undefined>,
  scope: string,
  emissions: EmissionData[]
) => {
  const recordScope1: Record<string, number> = {};
  emissions
    .filter((item) => item.scope === scope)
    .forEach((item) => {
      const key =
        (item.scope === "Scope-2" ? item.typeEn : item.type) ?? "Others";
      const old = recordScope1[key] ?? 0;
      recordScope1[key] = old + item.value;
    });
  return Object.entries(recordScope1).map(([key, value], index) => {
    const group = REPORT_GROUP.find(
      (item) =>
        item.scope === scope && item.id.toLowerCase() === key.toLowerCase()
    );

    return {
      id: index,
      value: value,
      label: t(group?.title ?? ""),
      color: group?.colors[1],
    };
  });
};

const Dashboard = () => {
  const context = useContext(DashbroadContext);
  const { t } = useTranslation(["common", "overview"], { nsMode: "fallback" });

  const scope1Sum = context.filteredEmissions
    .filter((item) => item.scope === "Scope-1")
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);
  const scope2Sum = context.filteredEmissions
    .filter((item) => item.scope === "Scope-2")
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);
  const scope3Sum = context.filteredEmissions
    .filter((item) => item.scope === "Scope-3")
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);
  const outsideOfScopeSum = context.filteredEmissions
    .filter((item) => item.scope === "Outside-of-Scope")
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);

  const emissionSum =
    process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
      ? scope1Sum + scope2Sum + outsideOfScopeSum + scope3Sum
      : scope1Sum + scope2Sum + outsideOfScopeSum;

  const seriesScope1 = getTypeSeries(t, "Scope-1", context.filteredEmissions);
  const seriesScope2 = getTypeSeries(t, "Scope-2", context.filteredEmissions);
  const seriesScope3 = getTypeSeries(t, "Scope-3", context.filteredEmissions);
  const seriesScopeOutside = getTypeSeries(
    t,
    "Outside-of-Scope",
    context.filteredEmissions
  );

  const byAssetRecord: Record<string, number> = {};
  const bySourceRecord: Record<string, number> = {};

  context.filteredEmissions.forEach((item) => {
    const asset = item.assetName ?? "";
    const oldAsset = byAssetRecord[asset] ?? 0;
    byAssetRecord[asset] = oldAsset + item.value;

    const source = item.attributeEn ?? "";
    const oldSource = byAssetRecord[source] ?? 0;
    bySourceRecord[source] = oldSource + item.value;
  });

  const getGroup = (emission: EmissionData) => {
    return emission.scope;
  };

  const pieLgSize = process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true" ? 3 : 4;

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
      {/* Overview Card */}
      <Grid container display={"flex"} spacing={"20px"}>
        <Grid flexGrow={1}>
          <OverviewCard
            title={t("Emission")}
            info={t("Total-Emission-Summary-Description")}
            text1={`${NumberFormatterUtils.numberFormat(emissionSum)} tCO2e`}
          />
        </Grid>
        <Grid flexGrow={1}>
          <OverviewCard
            title={t("Scope-1")}
            info={t("Scope1.Summary-Description")}
            text1={`${
              emissionSum === 0
                ? 0
                : NumberFormatterUtils.numberFormat(
                    (scope1Sum / emissionSum) * 100
                  )
            } %`}
            text2={`${NumberFormatterUtils.numberFormat(scope1Sum)} tCO2e`}
            icon="/img/scope1.svg"
          />
        </Grid>
        <Grid flexGrow={1}>
          <OverviewCard
            title={t("Scope-2")}
            info={t("Scope2.Summary-Description")}
            text1={`${
              emissionSum === 0
                ? 0
                : NumberFormatterUtils.numberFormat(
                    (scope2Sum / emissionSum) * 100
                  )
            } %`}
            text2={`${NumberFormatterUtils.numberFormat(scope2Sum)} tCO2e`}
            icon="/img/scope2.svg"
          />
        </Grid>

        {process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true" ? (
          <Grid flexGrow={1}>
            <OverviewCard
              title={t("Scope-3")}
              info={t("Scope3.Summary-Description")}
              text1={`${
                emissionSum === 0
                  ? 0
                  : NumberFormatterUtils.numberFormat(
                      (scope3Sum / emissionSum) * 100
                    )
              } %`}
              text2={`${NumberFormatterUtils.numberFormat(scope3Sum)} tCO2e`}
              icon="/img/scope3.svg"
            />
          </Grid>
        ) : null}

        <Grid flexGrow={1}>
          <OverviewCard
            title={t("Outside-of-Scope")}
            info={t("Outside-of-Scope.Summary-Description")}
            text1={`${
              emissionSum === 0
                ? 0
                : NumberFormatterUtils.numberFormat(
                    (outsideOfScopeSum / emissionSum) * 100
                  )
            } %`}
            text2={`${NumberFormatterUtils.numberFormat(
              outsideOfScopeSum
            )} tCO2e`}
            icon="/img/other.svg"
          />
        </Grid>
      </Grid>

      <EmissionCard
        t={t}
        title={t("Emission")}
        info={t("Emission-Description")}
        defaultBy={byChart}
        showByDropdown
        // groups={group}
        groups={scopes}
        getGroup={getGroup}
        emissions={context.emissions}
        startDate={context.startDate}
        endDate={context.endDate}
      />

      {/* Pie Card */}
      <Grid container display={"flex"} spacing={"20px"} flexWrap={"wrap"}>
        <Grid xs={12} md={6} lg={pieLgSize}>
          <OverviewPieCard
            title={t("Scope-1")}
            info={t("Scope1.Summary-Description")}
            icon="/img/scope1.svg"
            data={seriesScope1}
          />
        </Grid>
        <Grid xs={12} md={6} lg={pieLgSize}>
          <OverviewPieCard
            title={t("Scope-2")}
            info={t("Scope2.Summary-Description")}
            icon="/img/scope2.svg"
            data={seriesScope2}
          />
        </Grid>

        {process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true" ? (
          <Grid xs={12} md={6} lg={pieLgSize}>
            <OverviewPieCard
              title={t("Scope-3")}
              info={t("Scope3.Summary-Description")}
              icon="/img/scope3.svg"
              data={seriesScope3}
            />
          </Grid>
        ) : null}

        <Grid xs={12} md={6} lg={pieLgSize}>
          <OverviewPieCard
            title={t("Outside-of-Scope")}
            info={t("Outside-of-Scope.Summary-Description")}
            icon="/img/other.svg"
            data={seriesScopeOutside}
          />
        </Grid>
      </Grid>

      {/* Emission By Card */}
      <Grid container spacing={"20px"}>
        <Grid xs={12} md={6}>
          <EmissionByCard
            title={t("Emissions-by-Assets")}
            description={t("Emissions-by-Assets-Desc")}
            data={byAssetRecord}
            top={5}
          />
        </Grid>
        <Grid xs={12} md={6}>
          <EmissionByCard
            title={t("Emissions-by-Sources")}
            description={t("Emissions-by-Sources-Desc")}
            data={bySourceRecord}
            top={5}
          />
        </Grid>
      </Grid>
      <EmissionByBusinessCard />
    </Box>
  );
};

export default Dashboard;
