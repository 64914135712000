import { t } from "i18next";
import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import {
  Members,
  ResponseMembers,
} from "../models/dto/response/ResponseMembers";
import ResetPassword from "../pages/account/ResetPassword";

const UsersService = {
  getUsers: async (): Promise<Members | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.get("/v1/users");

      alertService.closeLoading();

      const result: ResponseMembers = response.data.results;

      return result.members;
    } catch (error: any) {
      handleLoginError(error);
    }
    return null;
  },
  invite: async (formData: any): Promise<Members | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.post("/v1/users/invite", formData);

      alertService.closeLoading();

      const result: ResponseMembers = response.data.results;

      return result.members;
    } catch (error: any) {
      handleLoginError(error);
    }
    return null;
  },
  activate: async (
    userRefId: string,
    formData: any
  ): Promise<boolean | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.post(
        `/v1/users/${userRefId}/activate`,
        formData
      );

      alertService.closeLoading();

      return true;
    } catch (error: any) {
      handleLoginError(error);
    }
    return false;
  },
  resetPassword: async (
    currentPassword: string,
    newPassword: string
  ): Promise<boolean | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.patch(`/v1/users/password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      });

      alertService.closeLoading();

      return true;
    } catch (error: any) {
      handleLoginError(error);
    }
    return false;
  },
};

const handleLoginError = async (error: any, year?: number) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    switch (errorMessage) {
      case "FORBIDDEN_ACCESS_ERROR":
        await alertService.error(
          t("Cannot-Change-Organization-Due-To-Permission-Error")
        );
        break;
      case "USERS_RETRIEVAL_PROCESS_FAILED":
        await alertService.error(t("failed_to_retrieve_users_in_organization"));
        break;
      case "VALIDATION_ERROR":
        await alertService.error(t("validation-failed"));
        break;
      case "USER_ALREADY_HAS_ACCESS":
        await alertService.error(
          t("User-Already-Has-Invitation-To-Organization-Error")
        );
        break;
      case "USER_ALREADY_ACTIVATED":
        await alertService.error(
          t("User-Already-Has-Invitation-To-Organization-Error")
        );
        break;
      case "WRONG_PASSWORD":
        await alertService.error(t("incorrect-password"));
        break;
      case "USER_NOT_FOUND":
        alertService.error(t("Please-Check-Your-Email-Or-Activate-Link"));
        break;
      default:
        await alertService.error(t("Cannot-Access-Organization-Error"));
        break;
    }
  } else {
    await alertService.error(t("cannot-connect-to-server"));
  }
};

export default UsersService;
