import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Activity } from "../../../models/Activities";
import FileUtils from "../../../utils/fileUtlis";
import { useEffect, useState } from "react";
import TableApprovalStatus from "./TableApprovalStatus";
import { SubOrganizations } from "../../../models/SubOrganization";
import InfoPopper from "../../../components/popper/InfoPopper";
import CustomInput from "../../../components/input/CustomInput";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useForm } from "react-hook-form";
import ActivitiesService from "../../../service/activitiesService";
import { AddEmissionContext } from "../../../contexts/AddEmissionContext";
import alertService from "../../../components/alert/alertService";
import CustomModal from "../../../components/modal/CustomModal";
import { INPUT_AUXILIARY, InputAuxiliary } from "../../../constants/form";
import { OrganizationBreadcrumbs } from "../OrganizationBreadcrumbs";
import { useAuth } from "../../../contexts/UserContext";

interface Props {
  data: Activity;
  subOrganizationList: SubOrganizations[];
  open: boolean;
  onClose: () => void;
  type: string; // view , approval
}

type FormValues = {
  comment: string;
};

export const ReviewApproval = (props: Props) => {
  const { t, i18n } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const { organization } = useAuth();

  const [file, setFile] = useState<File | null>(null);

  const [location, setLocation] = useState<string>("");

  const [dateOfActivity, setDateOfActivity] = useState<string>("");

  const [loaded, setLoaded] = useState<boolean>(false);

  const { setUpActivitiesListData } = AddEmissionContext();

  const [inputAuxiliary, setInputAuxiliary] = useState<InputAuxiliary>();

  useEffect(() => {
    if (loaded === false) {
      setInputAuxiliary(
        INPUT_AUXILIARY.find(
          (e) => e.emissionGroup === props.data.emissionGroup
        )
      );

      try {
        if (props.data.attachment !== "") {
          setFile(FileUtils.base64ToFile(props.data.attachment ?? ""));
        }
      } catch (error) {
        setFile(null);
      }

      const findResult = props.subOrganizationList.find(
        (i) => props.data.suborganizationName === i.name
      );

      const location = `${findResult?.location?.address ?? ""} ${
        findResult?.location?.district ?? ""
      } ${findResult?.location?.city ?? ""}`.trim();

      setLocation(location);

      const start: Dayjs = dayjs(props.data.startDate);

      const end: Dayjs = dayjs(props.data.endDate);

      if (start.month === end.month) {
        setDateOfActivity(start.format("MMM YYYY"));
      } else {
        setDateOfActivity(
          start.format(
            `${start.format("MMM YYYY")} - ${end.format("MMM YYYY")}`
          )
        );
      }

      setLoaded(true);
    }
  }, []);

  const close = () => {
    setFile(null);

    setLocation("");

    setDateOfActivity("");

    setLoaded(false);

    control._reset();

    props.onClose();
  };

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      comment: "",
    },
  });

  const onSubmit = async (data: FormValues, event: any) => {
    const result = await ActivitiesService.editStatus(
      props.data._id,
      event.nativeEvent.submitter.name === "approve" ? "approved" : "rejected"
    );

    if (result) {
      setUpActivitiesListData(result);

      alertService.mixin(
        event.nativeEvent.submitter.name === "approve"
          ? t("Emission-Approved-Success")
          : t("Emission-Rejected-Success")
      );

      close();
    }
  };

  return (
    <>
      {loaded ? (
        <CustomModal
          body={
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container width="100%" height={"80dvh"}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  padding={2.7}
                  display={"flex"}
                  flexDirection={"column"}
                  rowGap={2.5}
                  height={"100%"}
                  bgcolor={"var(--gray8)"}
                >
                  <Typography variant="h3" align="left">
                    | {t("Preview-Evidence")}
                  </Typography>

                  {file !== null && file.type.startsWith("image/") ? (
                    <iframe
                      title={props.data._id}
                      width="100%"
                      height="100%"
                      allowFullScreen
                      frameBorder="0"
                      style={{
                        backgroundColor: "var(--gray3)",
                        border: "0px !important",
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                      }}
                      loading="eager"
                      srcDoc={`
                        <html>
                          <head>
                            <style>
                              body {
                                margin: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                              }
                              img {
                                width: 100%;
                                height: auto;
                              }
                            </style>
                          </head>
                   <body>
                  <img src="${
                    URL.createObjectURL(file) ?? ""
                  }" alt="Selected Image" />
                </body>
                        </html>
                      `}
                    ></iframe>
                  ) : (
                    <iframe
                      width="100%"
                      height="100%"
                      title="null"
                      loading="eager"
                      frameBorder="0"
                      src={file ? URL.createObjectURL(file) : ""}
                    ></iframe>
                  )}

                  {file ? (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      bgcolor={"var(--white)"}
                      borderRadius={"4px"}
                      paddingY={"8px"}
                      paddingX={"12px"}
                      onClick={() => window.open(URL.createObjectURL(file))}
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          opacity: 0.75,
                        },
                      }}
                    >
                      <Typography
                        flex={1}
                        variant="h3"
                        align="left"
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {file?.name}
                      </Typography>

                      <IconButton>
                        <img src="/img/export.svg" alt="export" />
                      </IconButton>
                    </Box>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  padding={2}
                  height={"100%"}
                  display="flex"
                  flexDirection={"column"}
                  rowGap={"20px"}
                >
                  <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
                    <Typography align="left" variant="h3" color={"var(--dark)"}>
                      {t("Review")} EM-{props.data.id}
                    </Typography>

                    <TableApprovalStatus status={props.data.status} by="" />
                  </Box>

                  <OrganizationBreadcrumbs
                    organization={organization?.name ?? ""}
                    location={location}
                    subOrganizations={props.data.suborganizationName}
                    site={props.subOrganizationList
                      .find((i) => i.name === props.data.suborganizationName)
                      ?.sites.find((site) => site.name === props.data.siteName)}
                  />

                  {/* <Breadcrumbs
                    aria-label="breadcrumb"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      overflowX: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                      }}
                      gap={"8px"}
                    >
                      <img src="/img/buildings-black.svg" alt="icon" />

                      <Typography color="var(--gray4)" variant="h3">
                        {t("Organization")} : {props.data.suborganizationName}
                      </Typography>
                    </Box>

                    <Typography color="var(--dark)" variant="h3">
                      {t("Site")} : {location}
                    </Typography>

                    <Typography color="var(--dark)" variant="h3">
                      {t("Location")} : {location}
                    </Typography>
                  </Breadcrumbs> */}

                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    rowGap={"20px"}
                    paddingY={"24px"}
                    textAlign={"left"}
                    flex={1}
                    paddingX={0.8}
                    overflow={"auto"}
                  >
                    <Typography variant="h2" color={"var(--dark)"} align="left">
                      {t(props.data.emissionGroup)}
                      <InfoPopper
                        text={t(props.data.emissionGroup + "-Description")}
                      />
                    </Typography>

                    <CustomInput
                      label={t("Date-of-Activity")}
                      info={t("Date-of-Activity")}
                      value={dateOfActivity}
                      required
                      displayOnly
                      disabled
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={props.data.isMonthly ?? false}
                          name="monthly"
                        />
                      }
                      disabled
                      label={
                        <Typography variant="text1">{t("Monthly")}</Typography>
                      }
                    />

                    <CustomInput
                      label={t("Asset-Type")}
                      info={t("Asset-Type-Description")}
                      value={props.data.assetType}
                      required
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Asset-Name")}
                      info={t("Asset-Name-Description")}
                      required
                      value={props.data.assetName}
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Activity-Group")}
                      info={t("Activity-Group")}
                      value={
                        i18n.language === "th"
                          ? props.data.activityGroupTh
                          : props.data.activityGroupEN
                      }
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Activity-Type")}
                      info={t("Activity-Type")}
                      value={
                        i18n.language === "th"
                          ? props.data.activityTypeTh
                          : props.data.activityTypeEn
                      }
                      required
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Activity-Attribute")}
                      info={t("Activity-Attribute")}
                      value={
                        i18n.language === "th"
                          ? props.data.activityAttributeTh
                          : props.data.activityAttributeEN
                      }
                      required
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Activity-Emission-Factor-Publisher")}
                      info={t("Activity-Emission-Factor-Publisher")}
                      value={props.data.publisherName}
                      required
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Vendor")}
                      info={t("Vendor")}
                      value={props.data.vendor ?? ""}
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t("Unit")}
                      info={t("Unit")}
                      value={props.data.unit}
                      required
                      disabled
                      displayOnly
                    />

                    <CustomInput
                      label={t(inputAuxiliary?.amount.label ?? "")}
                      info={t(inputAuxiliary?.amount.info ?? "")}
                      value={`${props.data.amount}` ?? "0"}
                      required
                      disabled
                      displayOnly
                    />

                    {inputAuxiliary?.auxiliary?.map((e, i) => {
                      const item = props?.data?.auxiliary ?? null;

                      let result: any = JSON.stringify(item);

                      result = JSON.parse(result);

                      return e.label !== "" ? (
                        <CustomInput
                          label={t(e.label)}
                          info={t(e.info)}
                          value={result[`aux${i + 1}`]}
                          required
                          disabled
                          displayOnly
                        />
                      ) : null;
                    })}
                  </Box>

                  {props.type === "approval" ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      rowGap={"20px"}
                    >
                      <FormControl fullWidth>
                        <Controller
                          name="comment"
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <CustomInput
                              label={t("Comment")}
                              info={t("Comment")}
                              value={value}
                              handleOnChange={onChange}
                            />
                          )}
                        />
                      </FormControl>

                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        type="submit"
                        name="approve"
                      >
                        {t("Approve")}
                      </Button>

                      <Button
                        color="secondary"
                        variant="outlined"
                        type="submit"
                        name="reject"
                      >
                        {t("Reject")}
                      </Button>
                    </Box>
                  ) : null}

                  {props.type === "view" ? (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      rowGap={"20px"}
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => close()}
                      >
                        {t("Close")}
                      </Button>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          }
          open={props.open}
          onClose={() => close()}
        />
      ) : (
        <Box></Box>
      )}
    </>
  );
};
