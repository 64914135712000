import { Box } from "@mui/material";
import Layout from "../../../Layout";
import { AccessControlProvider } from "../../../contexts/AccessControlContext";
import { FilterAccessControl } from "./FilterAccessControl";
import { AdminTable } from "./AdminTable";
import { UsersTable } from "./UsersTable";
import { TabMenu, TabMenuData } from "../../../components/tabMenu/TabMenu";

export const tabMenuData: TabMenuData[] = [
  { pathname: "/setting/access-control", name: "Members" },
  {
    pathname: "/setting/accessility-and-permission",
    name: "Roles-And-Permissions",
    disabled: true,
  },
];

export const AccessControl = () => {
  return (
    <AccessControlProvider>
      <Layout>
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <TabMenu tabMenuData={tabMenuData} />
          <Box
            flex={1}
            p={"40px 40px"}
            gap={"40px"}
            display={"flex"}
            flexDirection="column"
            alignItems={"start"}
            bgcolor={"var(--white)"}
          >
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={"40px"}
              color={"var(--dark)"}
            >
              <FilterAccessControl />
              <AdminTable />
              <UsersTable />
            </Box>
          </Box>
        </Box>
      </Layout>
    </AccessControlProvider>
  );
};
