import { Box, Button, InputAdornment, TextField } from "@mui/material";
import Layout from "../../Layout";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import ImportFileHistoryTable from "./ImportFileHistoryTable";
import { OrganizationProvider } from "../../contexts/OrganizationContext";
import { ImportFilePopup } from "./ImportFilePopup";
import { ImportFileProvider } from "../../contexts/ImportFileContext";
import ImportFileService from "../../service/importFileService";
import FileUtils from "../../utils/fileUtlis";
import { TabMenu } from "../../components/tabMenu/TabMenu";
import { tabMenuEmissionData } from "../emission";

import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import IconSelect from "../../components/input/IconSelect";

export const createExcelDownloadLink = (fileName: string, base64: string) => {
  const file = FileUtils.base64ToFile(
    `${fileName}:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`
  );

  const fileURL = URL.createObjectURL(file);

  const link = document.createElement("a");
  link.href = fileURL;
  link.setAttribute("download", `${fileName.replace(".xlsx", "")}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const ImportData = () => {
  const { t } = useTranslation(["common", "input"], {
    nsMode: "fallback",
  });

  const downloadTemplates = async () => {
    const excelBase64 = await ImportFileService.getTemplate();

    if (excelBase64) {
      createExcelDownloadLink(
        excelBase64.fileName,
        excelBase64.templateInBase64
      );
    }
  };

  const [search, setSearch] = useState<string>("");

  const [status, setStatus] = useState<string>("all");

  const [inputOpen, setInputOpen] = useState<boolean>(false);

  const onMouseEnter = () => {
    setInputOpen(true);
  };

  const onBlur = (event: any) => {
    try {
      const input: any = document.getElementById("GridToolbarQuickFilter");

      if (input.value === "") {
        setInputOpen(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <OrganizationProvider>
        <ImportFileProvider>
          <Layout>
            <Box
              display={"flex"}
              flexDirection={"column"}
              flex={1}
              height={"100%"}
            >
              <TabMenu tabMenuData={tabMenuEmissionData} />

              <Box
                p={"40px 40px"}
                display={"flex"}
                flexDirection="column"
                bgcolor={"var(--white)"}
                gap={"40px"}
                alignItems={"start"}
                flex={1}
              >
                <Grid2 container width={"100%"} rowGap={"20px"}>
                  <Grid2
                    alignSelf={"center"}
                    xs={12}
                    md={6}
                    display={"flex"}
                    gap={"12px"}
                    flexDirection={{ xs: "column", md: "row" }}
                  >
                    <TextField
                      id="GridToolbarQuickFilter"
                      variant="outlined"
                      value={search}
                      placeholder={`${t("search")}...`}
                      onChange={(e) => setSearch(e.target.value)}
                      onMouseEnter={onMouseEnter}
                      // onMouseLeave={onBlur}
                      onBlur={onBlur}
                      sx={{
                        borderRadius: "4px",
                        margin: 0,
                        padding: 0,
                        width: inputOpen ? "100%" : "50px",
                        transition: ".5s",
                        maxWidth: "237px",
                      }}
                      inputProps={{
                        style: {
                          padding: "0px",
                        },
                      }}
                      InputProps={{
                        // disableUnderline: true,
                        sx: {
                          padding: "9px",
                          margin: 0,
                          width: "100%",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src="/img/search-normal.svg" alt="search" />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Box minWidth={"150px"}>
                      <IconSelect
                        listMenuItem={[
                          {
                            nameEN: t("All"),
                            nameTH: t("All"),
                            value: "all",
                          },
                          {
                            nameEN: t("Successfully"),
                            nameTH: t("Successfully"),
                            value: "importedSuccess",
                          },
                          {
                            nameEN: t("Failed"),
                            nameTH: t("Failed"),
                            value: "validatedFail",
                          },
                        ]}
                        value={status}
                        onChange={(event) => setStatus(event.target.value)}
                      />
                    </Box>
                  </Grid2>

                  <Grid2
                    xs={12}
                    md={6}
                    gap={"12px"}
                    display={"flex"}
                    justifyContent={"end"}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <img src="/img/document-download.svg" alt="" />
                      }
                      onClick={downloadTemplates}
                    >
                      {t("Download-Templates")}
                    </Button>

                    <ImportFilePopup />
                  </Grid2>
                </Grid2>

                <ImportFileHistoryTable search={search} status={status} />
              </Box>
            </Box>
          </Layout>
        </ImportFileProvider>
      </OrganizationProvider>
    </>
  );
};
