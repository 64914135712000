import { Box } from "@mui/material";
import { ReportDataTable } from "../ReportDataTable";
import Layout from "../../../Layout";
import { ReportProvider } from "../../../contexts/ReportContext";
import { ReportMenu } from "../ReportMenu";

export const TgoReport = () => {
  return (
    <>
      <Layout>
        <ReportMenu />
        <Box display={"flex"} flexDirection={"column"} flex={1}>
          <Box
            p={"40px 40px"}
            display={"flex"}
            flexDirection="column"
            bgcolor={"var(--white)"}
            gap={"40px"}
            alignItems={"start"}
            flex={1}
          >
            {/* <Grid2 container width={"100%"} rowGap={"20px"}>
              <Grid2 xs={12} md={6} display={{ xs: "none", md: "block" }}>
                <Typography variant="h1" color={"var(--dark)"}>
                  Filter
                </Typography>
              </Grid2>

              <Grid2
                xs={12}
                md={6}
                gap={"12px"}
                display={"flex"}
                justifyContent={"end"}
              >
                <OrganizationProvider>
                  <TgoGenerateReport />
                </OrganizationProvider>
              </Grid2>
            </Grid2> */}

            <ReportProvider>
              <ReportDataTable reportDataTableType="tgo-cfo" />
            </ReportProvider>
          </Box>
        </Box>
      </Layout>
    </>
  );
};
