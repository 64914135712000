import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { GetActivities } from "../models/Activities";
import { t } from "i18next";

const ActivitiesService = {
  createActivities: async (formData: any): Promise<GetActivities | null> => {
    let result: GetActivities | null = null;

    try {
      const response = await apiClient.post("/v1/activities", formData);

      result = response.data.results;
    } catch (error: any) {
      handleError(error);
      result = null;
    }
    return result;
  },
  editActivities: async (
    activityRefId: string,
    formData: any
  ): Promise<GetActivities | null> => {
    formData.activityRefId = activityRefId;

    try {
      const response = await apiClient.post("/v1/activities", formData);

      const result: GetActivities = response.data.results;

      return result;
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
  getActivities: async (year: number): Promise<GetActivities | null> => {
    try {
      alertService.showLoading();

      const response = await apiClient.get(`/v1/activities/${year}`);

      alertService.closeLoading();

      const result: GetActivities = response.data.results;

      return result;
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
  editActivitiesAmount: async (
    activitiesRef: string,
    amount: number
  ): Promise<GetActivities | null> => {
    try {
      const body = {
        amount: amount,
      };

      alertService.showLoading();

      const response = await apiClient.patch(
        `/v1/activities/${activitiesRef}`,
        body
      );

      alertService.closeLoading();

      return response.data.results;
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
  deleteActivities: async (
    activitiesRef: string
  ): Promise<GetActivities | null> => {
    try {
      const body = {
        status: "delete",
      };

      const response = await apiClient.patch(
        `/v1/activities/${activitiesRef}`,
        body
      );

      if (response.status === 200) {
        return response.data.results;
      }
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
  editattachment: async (
    activitiesRef: string,
    fileBase64: string
  ): Promise<GetActivities | null> => {
    let result = null;

    try {
      const body = {
        attachment: fileBase64, // if attachment is "", it means remove attachment
      };

      alertService.showLoading();

      const response = await apiClient.patch(
        `/v1/activities/${activitiesRef}`,
        body
      );

      alertService.closeLoading();

      if (response.status === 200) {
        return response.data.results;
      } else {
        result = null;
      }
    } catch (error: any) {
      alertService.error(t("upload-incomplete"));
    }
    return result;
  },
  editStatus: async (activitiesRef: string, status: string) => {
    try {
      const body = {
        status: status,
      };

      alertService.showLoading();

      const response = await apiClient.patch(
        `/v1/activities/${activitiesRef}`,
        body
      );

      alertService.closeLoading();

      if (response.status === 200) {
        return response.data.results;
      } else {
        return null;
      }
    } catch (error: any) {
      handleError(error);
    }
    return null;
  },
};

const handleError = async (error: any) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    switch (errorMessage) {
      case "VALIDATION_ERROR":
        alertService.error(t("validation-failed"));
        break;
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "ACTIVITY_UPSERT_PROCESS_FAILED":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "SUBORGANIZATION_ALREADY_EXISTS":
        alertService.error(t("SUBORGANIZATION_ALREADY_EXISTS"));
        break;
      case "FORBIDDEN_ACTION_ERROR":
        alertService.info(
          t("FORBIDDEN_ACTION_ERROR"),
          t("Please-Upgrade-Package")
        );

        break;
      case "PLAN_EXPIRED":
        alertService.info(t("PLAN_EXPIRED"), t("Please-Upgrade-Package"));
        break;
      default:
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};

export default ActivitiesService;
