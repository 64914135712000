import Layout from "../../Layout";
import { Box } from "@mui/material";
import { AddEmissionProvider } from "../../contexts/AddEmissionContext";
import DataTable from "./data-table/EmissionDataTable";
import { TabMenu, TabMenuData } from "../../components/tabMenu/TabMenu";

export const tabMenuEmissionData: TabMenuData[] = [
  { pathname: "/emission/add", name: "Add-Data" },
  {
    pathname: "/emission/import-data",
    name: "Import-Data",
  },
  {
    pathname: "/emission/import-data",
    name: "Eco-Efficiency",
    disabled: true,
  },
];

const AddEmission = () => {
  return (
    <Layout>
      <Box display={"flex"} flexDirection={"column"} flex={1} height={"100%"}>
        <TabMenu tabMenuData={tabMenuEmissionData} />

        <Box
          p={"40px 40px"}
          display={"flex"}
          flexDirection="column"
          bgcolor={"var(--white)"}
          gap={"40px"}
          alignItems={"start"}
          flex={1}
        >
          <AddEmissionProvider>
            {/* <ScopeSummary /> */}

            {/* <Box width={"100%"} textAlign={"right"}>
              <AddEmissionForm />
            </Box> */}

            <DataTable />
          </AddEmissionProvider>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddEmission;
